
import { defineComponent, reactive, watch } from 'vue'
import { useStore } from 'vuex'
// import * as _ from 'lodash'
import { debounce } from "throttle-debounce"

import httpRequest from '@/service'

import conBox from './cpns/conBox.vue'
import list from './cpns/list.vue'
import tree from './cpns/tree.vue'

export default defineComponent({
  name: 'LeftPane',
  components: {
    conBox,
    list,
    tree
  },
  setup() {
    const store = useStore()
    const initCount = debounce(80, () => {
      leixing.loading++
      anyou.loading++
      fayuanjibie.loading++
      guanjianci.loading++
      shenlichegnxu.loading++
      shenlifayuan.loading++
      shenpannianfen.loading++
      httpRequest
        .post({ url: 'caseLibraryGroupCount', data: store.getters.getCaseLibraryApiParam })
        .then((res: any) => {
          leixing.data = res.data.leixing
          anyou.data = res.data.anyou
          fayuanjibie.data = res.data.fayuanjibie
          guanjianci.data = res.data.guanjianci
          shenlichegnxu.data = res.data.shenlichegnxu
          shenlifayuan.data = res.data.shenlifayuan
          shenpannianfen.data = res.data.shenpannianfen
          leixing.loading--
          anyou.loading--
          fayuanjibie.loading--
          guanjianci.loading--
          shenlichegnxu.loading--
          shenlifayuan.loading--
          shenpannianfen.loading--
        })
        .catch((err) => {
          leixing.loading--
          anyou.loading--
          fayuanjibie.loading--
          guanjianci.loading--
          shenlichegnxu.loading--
          shenlifayuan.loading--
          shenpannianfen.loading--
          console.error(err)
        })
    })

    watch(
      () => store.getters.getCaseLibraryApiParam,
      () => {
        initCount()
      }
    )
    initCount()

    const leixing: any = reactive({
      title: '案件类别',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'type' + item.id,
          param: 'type',
          value: item.id,
          label: item.name,
          replace: true
        })
      }
    })

    const anyou: any = reactive({
      title: '案由',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'sortId' + item.id,
          param: 'sortId',
          value: item.id,
          label: item.name
        })
      }
    })

    const fayuanjibie: any = reactive({
      title: '法院级别',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'fyjbId' + item.id,
          param: 'fyjbId',
          value: item.id,
          label: item.name
        })
      }
    })

    const guanjianci: any = reactive({
      title: '关键词',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'keyword' + item.id,
          param: 'keyword',
          value: item.id,
          label: item.name
        })
      }
    })

    const shenlichegnxu: any = reactive({
      title: '审理程序',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'slcxId' + item.id,
          param: 'slcxId',
          value: item.id,
          label: item.name
        })
      }
    })

    const shenlifayuan: any = reactive({
      title: '审理法院',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'slfyId' + item.id,
          param: 'slfyId',
          value: item.id,
          label: item.name
        })
      }
    })

    const shenpannianfen: any = reactive({
      title: '审判年份',
      key: 'id',
      data: [],
      loading: 0,
      handleItemClick(item: any) {
        store.commit('addResultApiParam', {
          id: 'cprqYear' + item.id,
          param: 'cprqYear',
          value: item.id,
          label: item.name
        })
      }
    })

    return {
      leixing,
      anyou,
      fayuanjibie,
      guanjianci,
      shenlichegnxu,
      shenlifayuan,
      shenpannianfen
    }
  }
})
