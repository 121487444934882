<template>
  <div class="card" v-loading="listDataState.loading > 0">
    <p class="infos">
      总共检索到 <span class="total-value">{{ totalState.total.toLocaleString() }}</span> 篇
    </p>
    <div class="dataNone" v-if="listDataState.flag">暂无数据</div>
    <div v-if="!listDataState.flag">
      <!-- 功能框 -->
      <tool-box :sortData="sortState.data" :sortId="sortState.id" :checkAllFlag="checkedState.allFlag"
                @checked="checkedState.handleCheckAll" :checkedAmount="checkedState.checkedAmount"
                @changeSort="sortState.handleClick"></tool-box>
      <div v-for="single in listDataState.group">
        <div class="list-title">
          <div class="label">
            <i class="icon"></i>
            <h4 class="title">{{ single.name }}</h4>
          </div>
          <!-- todo:出现的时机和点击事件 -->
          <!-- <router-link
            to=""
            class="more"
            v-if="single.data.total > single.data.list.length"
            @click="listDataState.handleItemClick(single)"
            >更多</router-link
          > -->
        </div>
        <div class="item-list" v-for="(item, index) in single.data.list" :key="index" :ref="setItemRef">
          <div class="list-content">
            <el-checkbox
              class="checkbox"
              v-model="item.flag"
              @change="checkedState.handleCheckSingle(item)">
            </el-checkbox>
            <div class="list-content-right">
              <div class="right-top">
                <div class="case-type">
                  {{ item.cpwsAlType === '01' ? '指导性案例' : '参考案例' }}
                </div>
                <el-tooltip
                  v-if="item.cpwsAlTitleHighlight || item.cpwsAlTitle"
                  class="item"
                  effect="dark"
                  :content="item.cpwsAlTitle"
                  placement="top-start">
                  <router-link
                    :to="{
                      name: 'CaseLibraryDetail',
                      query: {
                        id: item.id,
                        sourceFlag: true,
                        keyword: listDataState.searchData
                      }
                    }"
                    class="list-content-name"
                    target="_blank">
                    <div v-html="item.cpwsAlTitleHighlight || item.cpwsAlTitle"></div>
                  </router-link>
                </el-tooltip>
              </div>
              <div class="list-item-case">
                <!--              <span class="case-item" v-if="item.cause">{{ `${item.cause && item.cause.join(',').replace(/<[^>]+>/g,-->
                <!--                '')}` }}</span>-->
                <span class="case-item" v-if="item.cpwsAlInfos">
                  {{ item.cpwsAlInfos.substring(item.cpwsAlInfos.indexOf('/') + 1) }}
                </span>
                <!--              <span class="case-item" >123</span>-->
                <!--              <span class="case-item" v-if="item.caseNo">{{ item.caseNo }}</span>-->
                <!--              <span class="case-item"> {{ item.cpwsAlZsDate }}</span>-->
                <!-- :title="'案由：' + i.case" -->
                <!-- 部分数据由html片段包含 -->
              </div>
              <div class="list-item-yaodian">
                <TextExpand :data="item.cpwsAlCpyzHighlight || item.cpwsAlCpyz"/>
              </div>
            </div>
            <!-- 操作下拉框 -->
            <!-- <handle-box :currentI="item"> </handle-box> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 分页 -->
  <div class="demo-pagination-block">
    <el-pagination v-model:currentPage="paginationState.currentPage" layout="prev, pager, next, jumper"
                   :total="paginationState.pageTotal" @update:current-page="paginationState.handleCurrentChange"
                   :prev-text="paginationState.prevText" :next-text="paginationState.nextText">
    </el-pagination>
  </div>
</template>
<script setup>
import { reactive, watch } from 'vue'
import { useStore } from 'vuex'
// import * as _ from 'lodash'
import { debounce } from "throttle-debounce"
import { ElMessage } from 'element-plus/lib/components'

import httpRequest from '@/service'

import toolBox from './cpns/toolBox.vue'
import TextExpand from './cpns/TextExpand.vue'


const store = useStore()

watch(
  () => store.getters.getCaseLibraryApiParam,
  () => {
    listDataState.getData()
  }
)

const sortState = reactive({
  id: '0',
  data: [{
    type: '审判日期',
    list: [
      {
        name: '降序',
        id: '1'
      },
      {
        name: '升序',
        id: '2'
      }
    ]
  }, {
    type: '相似度',
    list: [
      {
        name: '降序',
        id: '0'
      }
    ]
  }],
  getData() {
  },
  handleClick: (value) => {
    sortState.id = value,
      listDataState.getData()
  }
})
// sortState.getData()
// 总体数量
const totalState = reactive({
  total: 0

})
// totalState.getData()

// 分页
const paginationState = reactive({
  currentPage: 1,
  num: 0,
  pageTotal: 0,
  prevText: '上一页',
  nextText: '下一页',
  handleCurrentChange: (val) => {
    paginationState.currentPage = val
    listDataState.getData()
  }
})

// 计算高度显示展开按钮
const itemRefs = []
const setItemRef = el => {
  if (el) {
    itemRefs.push(el)
  }
}

// 列表数据
const listDataState = reactive({
  loading: 0,
  flag: false,
  data: [],
  group: [],
  searchData: [],
  handleItemClick(item) {
    store.commit('addResultApiParam', {
      id: 'type' + item.id,
      param: 'type',
      value: item.id,
      label: item.name,
      replace: true
    })
    listDataState.getData()
  },
  getData: debounce(80, () => {
    const _this = listDataState
    _this.loading++
    if (paginationState.currentPage > 10000) {
      ElMessage({
        message: '无法查询超过10000条数据',
        type: 'warning'
      })
    }
    const params =  {
      cprqSort: sortState.id === '0' ? null : (sortState.id === '1' ? 'desc' : 'asc'),
      ...store.getters.getCaseLibraryApiParam,
      pageNum: paginationState.currentPage,
      pageSize: 5
    }
    if(params.type){
      params.pageSize = 10
    }
    httpRequest.post({
      // url: 'caseLibrarySearch',
      url: 'caseLibrarySearchGroup',
      data: params
    }).then((res) => {
      checkedState.checkedAmount = []
      checkedState.checkAmount = []
      checkedState.allFlag = false
      // 分组模式新写start
      let list = []
      let total = 0
      res.data.forEach(item=>{
        list.push(...item.data.list)
        total += item.data.total
      })
      _this.group = res.data
      // 分组模式新写end
      // let list = res.data.list
      // let total = res.data.total
      totalState.total = total
      store.state.caseLibrary.total = total
      if (JSON.stringify(store.state.form) === '{}') {
        store.commit('setTotal', total)
      }
      if (!list.length) {
        _this.flag = true
        _this.searchData = []
      } else {
        list.forEach((item) => {
          item.flag = false
          checkedState.checkAmount.push(item.id)
        })
        _this.data = list
        _this.searchData = []
        _this.flag = false
        paginationState.pageTotal = total - 0

        // 每次调取接口都将页面回到顶部
        document.documentElement.scrollTop = 0
      }
      _this.loading--
    }).catch((err) => {
      _this.loading--
      console.error(err)
      ElMessage.error('查询失败')
    })
  })
})
listDataState.getData()
// 全选
const checkedState = reactive({
  allFlag: false,
  checkAmount: [],
  checkedAmount: [],
  handleCheckAll: (val) => {
    checkedState.allFlag = val
    if (val) {
      checkedState.checkedAmount = JSON.parse(
        JSON.stringify(checkedState.checkAmount)
      )
      listDataState.data.forEach((item) => {
        item.flag = true
      })
    } else {
      checkedState.checkedAmount = []
      listDataState.data.forEach((item) => {
        item.flag = false
      })
    }
  },
  handleCheckSingle: (i) => {
    let checkedIndex = checkedState.checkedAmount.indexOf(i.id)
    if (checkedIndex == -1 && i.flag) {
      checkedState.checkedAmount.push(i.id)
      i.flag = true
    } else {
      checkedState.checkedAmount.splice(checkedIndex, 1)
      i.flag = false
    }

    checkedState.allFlag =
      checkedState.checkedAmount.length === checkedState.checkAmount.length
  }
})

</script>

<style lang="less" scoped>
/deep/ a {
  line-height: 20px;
  display: inline-block;
  max-width: calc(100% - 100px);
}

.card {
  .infos {
    text-align: right;

    .total-value {
      color: #449fe6;
    }
  }

  .dataNone {
    margin-top: 20px;
  }

  .list-title {
    position: relative;
    .label {
      height: 70px;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 23px;
        height: 19px;
        background: url('~@/assets/images/searchList/icon-title.png') no-repeat;
      }
      .title {
        font-size: 18px;
        color: #1c8ffa;
        margin: 0 10px;
        font-weight: 500;
      }
      .total {
        font-size: 18px;
        color: #1c8ffa;
        font-weight: 500;
      }
    }
    .more {
      font-size: 16px;
      float: right;
      position: absolute;
      top: 27px;
      right: 12px;
      color: #449fe6;
    }
  }
  .item-list {
    .list-content {
      border-top: 1px solid #f3f3f3;
      display: flex;
      padding: 20px 10px 10px;
      position: relative;

      &:deep(em) {
        font-style: normal;
        color: red;
      }

      .list-content-name {
        color: #333;
        display: block;
        //margin-top: 7px;

        &:hover {
          color: #1c7fef;
        }
        >div{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        :deep(p) {
          color: #e34242;
          display: inline-block;
        }
      }

      .list-content-right {
        flex: 1;
        //margin-right: 36px;
        width: calc(100% - 56px);
      }

      .right-top {
        display: flex;
        // height: 5vh;
        line-height: 6vh;
        padding-bottom: 10px;
        align-items: center;
        padding-top: 5px;

        > * {
          display: block;
        }

        // .icon-content {
        //   position: relative;
        //   width: 8vw;
        //   height: 2.4vh;
        // }

        .case-type {
          margin-right: 10px;
          padding: 2px 8px;
          font-size: 14px;
          border-radius: 2px;
          background-color: #4e84ee;
          line-height: 20px;
          color: #fff;
        }
      }

      .checkbox {
        margin: 0 10px;
      }

      .select {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;

        .el-dropdown-link:hover {
          color: #198efa;
        }
      }

      .list-name-aspect {
        // width: 58px;
        // height: 25px;
        display: inline-block;
        border-radius: 5px;
        text-align: center;
        line-height: 25px;
        margin-left: 20px;
        font-size: 14px;
        padding: 1px 5px;
      }

      .positive {
        background-color: #ffe7e7;
        color: #e63939;
      }

      .none {
        background-color: #fff6e7;
        color: #f29400;
      }

      .list-item-case {
        font-size: 14px;
        // background: #f9f9f9;
        line-height: 18px;
        color: #666;
        // padding: 5px 20px 10px;
        // width: 62%;
        display: flex;
        padding-bottom: 8px;

        // padding-top: 8px;
        // p {
        //   text-overflow: -o-ellipsis-lastline;
        //   overflow: hidden; //溢出内容隐藏
        //   text-overflow: ellipsis; //文本溢出部分用省略号表示
        //   display: -webkit-box; //特别显示模式
        //   -webkit-line-clamp: 2; //行数
        //   line-clamp: 2;
        //   -webkit-box-orient: vertical; //盒子中内容竖直排列
        // }
        .case-item:nth-child(1) {
          // flex: 1;
          padding-left: 0;
          margin-bottom: 5px;
        }

        // .case-item:nth-child(2) {
          //line-height: 30px;
          // flex: 2;
        // }

        // .case-item:nth-child(1) {
          // flex: 1;
        // }

        .case-item {
          display: inline-block;
          /* border-right: 2px black solid; */
          font-size: 14px;
          color: #999999;
          text-align: left;
          padding: 0px 20px;
          //border-right: 2px solid #e2e2e2;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 1; //行数
          line-clamp: 1;
        }
      }

      .list-content-info {
        display: flex;
        margin: 18px 0px;

        li {
          font-size: 14px;
          color: #999999;
          padding: 0px 20px;
          border-right: 2px solid #e2e2e2;
        }

        li:first-child {
          padding-left: 0px;
        }

        li:last-child {
          border: none;
        }
      }

      .type-box {
        display: flex;
        align-items: center;

        .click-button {
          height: 22px;
          background: #f2f2f2;
          border: none;
        }
      }

      .list-match-artical {
        width: 100%;
        background: #f9f9f9;
        padding: 5px 20px 10px;
        margin: 15px 0 10px;

        .list-artical-ul li {
          width: 100%;
          padding: 10px 0px;
          line-height: 24px;
          border-bottom: 1px solid #ececec;

          a {
            color: #333;
            font-size: 14px;
          }

          h5 {
            display: inline-block;
            margin-right: 5px;
          }

          :deep(p) {
            display: inline-block;
            color: #e34242;
          }
        }

        .list-match-tool {
          padding-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .list-match-total {
          padding: 2px 10px;
          font-size: 14px;
          background: #e9f4ff;
          color: #449fe6;
          border-radius: 3px;
        }

        .list-match-more {
          font-size: 14px;
          color: #449fe6;
        }
      }
    }
  }
}
</style>
<style lang="less">
.demo-pagination-block {
  border-top: 1px solid #f3f3f3;
}

.demo-pagination-block {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.el-checkbox__label {
  font-size: 16px;
}

.list-item-yaodian {
  color: #333;
  font-size: 14px;
  background-color: #F9F9F9;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
